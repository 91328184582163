<template>
  <div class="col main_categories_wrapper">
    <card>
      <!-- Start Server Table For All Categories  -->
      <server-table
        class=""
        :count="allTaskCategoriesCount"
        :DataItems="allTaskCategories"
        :filter="filter"
        @Refresh="Refresh"
        @reset="filter = {}"
        :hidden_export_import="true"
        :title="`${localization('Task Categories')}`"
        admin_permission="show_users"
        edit_permission="show_users"
      >
        <template #columns>
          <el-table-column
            prop="name"
            :label="`${localization('Category Name')}`"
          >
            <template slot-scope="scope">
              <!-- <div
                style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: normal;
                word-break: break-word;
                width: 100%;
              "
              >
                {{ scope.row.name }}
              </div> -->

              <div class="wrap_every_category">
                <div class="part_a">
                  {{ scope.row.name }}
                </div>
                <div class="td_actions part_b">
                  <router-link
                    :to="`/task-categories/${scope.row.id}`"
                    v-tooltip.top-center="`${localization('Show category')}`"
                    class="actions-icons add"
                  >
                    <i class="fa fa-eye"></i>
                  </router-link>
                  <a
                    @click="open_add_category_dialog(2, scope.row)"
                    v-tooltip.top-center="`${localization('Edit category')}`"
                    class="  actions-icons edit"
                  >
                    <i class="fa fa-edit"></i
                  ></a>
                  <a
                    @click="deleteCategory(scope.row.id)"
                    v-tooltip.top-center="`${localization('Delete category')}`"
                    class=" actions-icons delete"
                    :class="{ 'd-none': scope.row.name == 'default' }"
                  >
                    <i class="fa fa-times"></i
                  ></a>
                </div>
              </div>
            </template>
          </el-table-column>
        </template>

        <!-- Add Category  -->
        <template #action>
          <div
            type="info"
            class="ml-2"
            @click="open_add_category_dialog(1, {})"
          >
            <el-icon class="el-icon-plus"></el-icon>
            {{ localization("Add Category") }}
          </div>
        </template>
      </server-table>
      <!-- End Server Table For All Categories  -->
    </card>
    <!-- Start Add Or Edit New Category -->
    <el-dialog
      :title="
        type == 1
          ? `${localization('Add Category')}`
          : `${localization('Edit category')}`
      "
      :visible.sync="add_category_dialog"
      width="50%"
      top="4vh"
    >
      <el-form :model="add_category_name_data" :rules="rules" ref="addTeam">
        <el-form-item :label="`${localization('Name')}`" prop="name">
          <el-input v-model="add_category_name_data.name"></el-input>
        </el-form-item>
        <el-form-item label="Mode" prop="mode">
          <el-select
            v-model="add_category_name_data.mode"
            clearable
            placeholder="Mode"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in modeTypes"
              :key="index"
              :label="item.mode"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer dialog_footer">
        <el-button
          @click="close_add_category_dialog"
          class="text-center d-block"
          >{{ localization("Cancel") }}</el-button
        >
        <el-button
          type="submit"
          @click="addCategory"
          class="text-center d-block text-capitalize"
          >{{ localization("Save") }}</el-button
        >
      </div>
    </el-dialog>
    <!--End Add Or Edit New Category -->
  </div>
</template>

<script>
import { Card, Table as LTable } from "src/components/index";
import ServerTable from "../../components/DataTablePagination.vue";
import "element-ui/lib/theme-chalk/display.css";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      filter: {},
      rules: {
        name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        mode: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      },
      add_category_name_data: {
        name: null,
        mode: null
      },
      type: null,
      add_category_dialog: false,
      modeTypes: [
        {
          id: 1,
          mode: "In School"
        },
        {
          id: 2,
          mode: "At Work"
        },
        {
          id: 3,
          mode: "Career Match"
        }
      ]
    };
  },
  components: {
    Card,
    ServerTable,
    LTable
  },

  mounted() {
    this.Refresh();
  },
  computed: {
    allTaskCategories() {
      return this.$store.getters["attributes/getTaskCategories"];
    },
    allTaskCategoriesCount() {
      return this.$store.getters["attributes/getTaskCategoriesCount"];
    }
  },
  methods: {
    Refresh(query) {
      this.$store.dispatch("attributes/GetAllTaskCategories", {
        query: query
      });
    },
    // open add Category dialog
    open_add_category_dialog(type, item) {
      this.add_category_dialog = !this.add_category_dialog;
      this.add_category_name_data = item;
      this.type = type;
      console.log(item);
    },
    // close add Category dialog
    close_add_category_dialog() {
      this.Refresh();
      this.add_category_dialog = false;
      this.add_category_name_data = {};
      this.type = null;
    },
    // Add Or Edit Category
    addCategory() {
      this.$refs["addTeam"].validate(valid => {
        if (valid) {
          if (this.type == 1) {
            this.$store
              .dispatch("attributes/addNewCategory", {
                name: this.add_category_name_data.name
              })
              .then(() => {
                this.add_category_dialog = !this.add_category_dialog;
                this.add_category_name_data.name = "";
                this.Refresh();
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: "Category has been added",
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false,
                  confirmButtonText: "ok"
                });
              });
          } else {
            this.$store
              .dispatch("attributes/addNewCategory", {
                name: this.add_category_name_data.name,
                id: this.add_category_name_data.id
              })
              .then(() => {
                this.add_category_dialog = !this.add_category_dialog;
                this.add_category_name_data.name = "";
                this.add_category_name_data.id = null;
                this.Refresh();
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: "Category has been updated",
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false,
                  confirmButtonText: "ok"
                });
              });
          }
        }
      });
    },
    deleteCategory(id) {
      Swal.fire({
        title: "",
        text: `Are you sure to delete this Category?`,
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: "Delete",
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          this.$store.dispatch("attributes/DeleteCategory", id).then(_ => {
            this.Refresh(null);
            Swal.fire({
              title: "",
              text: "Category has been deleted.",
              icon: "success",
              confirmButtonClass: "btn btn-success",
              buttonsStyling: false
            });
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.main_categories_wrapper {
  .wrap_every_category {
    // background: #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px 0;
    @media (min-width: 320px) and (max-width: 480px) {
      display: block;
    }
    .part_a {
      white-space: normal;
      word-break: break-word;
    }
    .part_b {
      display: flex;
      gap: 10px;
      @media (min-width: 320px) and (max-width: 480px) {
        margin-top: 5px;
      }
      .actions-icons {
        font-size: 14px;
        width: 30px;
        height: 30px;
        box-shadow: 0 0 6px 0 rgba(#000, 0.2), 0 0 5px -2px rgba(#000, 0.1);
        text-align: center;
        line-height: 30px;
        border-radius: 50%;
        cursor: pointer;
        color: #777;
        transition: 0.3s;

        &.add {
          &:hover {
            background-color: #11bfe3;
            color: #fff;
          }
        }

        &.edit {
          &:hover {
            background-color: #ffa534;
            color: #fff;
          }
        }

        &.delete {
          &:hover {
            background-color: #fb404b;
            color: #fff;
          }
        }
      }
    }
  }
}

::v-deep .el-dialog {
  @media (min-width: 320px) and (max-width: 480px) {
    width: 90% !important;
  }
  @media (min-width: 480px) and (max-width: 767px) {
    width: 70% !important;
  }
}
.dialog_footer {
  display: flex;
  justify-content: end;
  align-items: center;
  @media (min-width: 320px) and (max-width: 991px) {
    justify-content: center;
  }
}
</style>
